import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import { OnboardingFormProps } from "../Types";
import flyingPaperPlaneGif from "../../../assets/animations/flying_paper_plane.gif";
import onboardingService from "../../../services/onboardingService";

const InstantSampleStep: React.FC<OnboardingFormProps> = ({ formData, onChange }) => {

  const [instantSampleHtml, setInstantSampleHtml] = useState('<div></div>');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);

  const loadingMessages = [
    "Assembling your content...",
    "Formatting the layout...",
    "Crafting engaging headlines...",
    "Tailoring content to your audience...",
    "Proofreading for polish...",
    "Reviewing for clarity...",
    "Adding final touches...",
    "Almost there...",
  ];

  const debouncedGenerateSample = useCallback(
    debounce((audienceOfOne: string, companyWebsite: string) => {
      setIsLoading(true);
      onboardingService.generateOnboardingInstantSample(audienceOfOne, companyWebsite);
    }, 50), []);

  useEffect(() => {
    setIsLoading(true);

    if (formData.audienceOfOne) {
      debouncedGenerateSample(formData.audienceOfOne, formData.companyWebsite);
    }

    // cycle through loading messages with an animation
    const intervalId = setInterval(() => {
      setLoadingMessageIndex(
        (prevIndex) => (prevIndex + 1) % loadingMessages.length
      );
    }, 4000);

    // poll the backend for a new instant sample generated after queueing job
    const afterTimestamp = new Date().toISOString();

    const pollingIntervalId = setInterval(() => {
      console.log('Polling for instant sample');
      onboardingService.getOnboardingInstantSample(afterTimestamp)
      .then((sampleHtml) => {
        // server returns '' if no updated html is found
        if (sampleHtml) {
          setInstantSampleHtml(sampleHtml);
          setIsLoading(false);
          clearInterval(pollingIntervalId);
        }
      });
    }, 2000);

    // cleanup function to cancel pending calls
    return () => {
      debouncedGenerateSample.cancel();
      clearInterval(intervalId);
      clearInterval(pollingIntervalId);
    };
  }, [formData.audienceOfOne, debouncedGenerateSample, loadingMessages.length]);

  return (
    <div>
      <h2>Sample Newsletter</h2>
      <p style={{ marginBottom: "16px" }}>
        Example content for your blueprint. Potions will populate this with real content weekly.
      </p>

      { isLoading &&
        <>
          <img src={flyingPaperPlaneGif} alt="Flying paper plane" />
          <p className="instant-sample-loading-message"><b>{loadingMessages[loadingMessageIndex]}</b></p>
        </>
      }

      { !isLoading &&
        <div
          dangerouslySetInnerHTML={{
            __html: instantSampleHtml,
          }}
        />
      }
    </div>
  );
};

export default InstantSampleStep;
