import React, { useEffect, useRef } from "react";
import { OnboardingFormProps } from "../Types";

const NewsletterLinkStep: React.FC<OnboardingFormProps> = ({ formData, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="primary-input-container">
      <h2>Your Newsletter Link</h2>
      <p>
        Potions will intelligently fetch your newsletter's blueprint using this URL.
      </p>
      <input
        ref={inputRef}
        type="url"
        value={formData.newsletterLink}
        onChange={e => onChange("newsletterLink", e.target.value)}
        placeholder="https://withpotions.com/blog/2024-recap"
        className="primary-input"
      />
    </div>
  )
};

export default NewsletterLinkStep;
