import React from "react";
import { useSelector } from "react-redux";
import { selectUserEmail } from "../store/slices/authUserSlice";

const Settings: React.FC = () => {

  const userEmail = useSelector(selectUserEmail);

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
      <h1>Settings</h1>

      <div className="primary-input-container"
        style={{maxWidth: "600px"}}
      >
        <h3>Cancel Your Subscription</h3>
        <p>You can cancel your subscription <a href="https://billing.stripe.com/p/login/5kA7tpbg6cSJ8gwbII">logging
          into your Stripe account here</a>.</p>
      </div>

      <div style={{ marginTop: "auto" }}>
        <h3>Email</h3>
        <p style={{ marginBottom: "32px" }}>{userEmail}</p>
      </div>
    </div>
  );
};

export default Settings;
