import axios from "axios";
import { getApiUrlWithVersion } from "./environmentService";
import { getAuthUser } from "./authService";
import { NewsletterSubscriber } from "../types/newsletterEdition";

export const uploadSubscribers = async (subscribers: NewsletterSubscriber) => {
  const response = await axios.post(
    `${getApiUrlWithVersion()}/subscribers`,
    { subscribers },
    { headers: getAuthHeaders() },
  );

  return response.data;
};

export const getSubscribers = async () => {
  const response = await axios.get(`${getApiUrlWithVersion()}/subscribers`, { headers: getAuthHeaders() });

  return response.data;
}

const getAuthHeaders = () => ({ Authorization: getAuthUser()?.accessToken });
