// App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Analytics from "./components/Analytics";
import Settings from "./components/Settings";
import Posts from "./components/Posts";
import Blueprint from "./components/Blueprint";
import ProtectedRoute from "./components/ProtectedRoute";
import Onboarding from "./components/Onboarding";
import NewsletterEdition from "./components/NewsletterEdition";
import Sources from "./components/Sources";
import PageWithLayout from "./components/PageWithLayout";
import { ReduxStateProvider } from "./context/ReduxStateProvider";
import StatsigMetricsProvider from "./context/StatsigMetricsProvider";
import Help from "./components/Help";
import Subscribers from "./components/Subscribers";

const App: React.FC = () => {
  return (
    <ReduxStateProvider>
      <StatsigMetricsProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <PageWithLayout>
                  <Login />
                </PageWithLayout>
              }
            />
            <Route
              path="/login"
              element={
                <PageWithLayout>
                  <Login />
                </PageWithLayout>
              }
            />
            <Route
                path="/help"
                element={
                  <PageWithLayout>
                    <Help />
                  </PageWithLayout>
                }
            />
            <Route
              path="/signup"
              element={
                <PageWithLayout>
                  <Signup />
                </PageWithLayout>
              }
            />
            <Route
              element={
                <ProtectedRoute redirectPathWhenUnauthenticated="/signup" />
              }>
              <Route
                path="/onboarding"
                element={
                  <PageWithLayout>
                    <Onboarding />
                  </PageWithLayout>
                }
              />
            </Route>
            <Route
              element={<ProtectedRoute redirectPathWhenUnauthenticated="/login" />}>
              <Route
                path="/analytics"
                element={
                  <PageWithLayout>
                    <Analytics />
                  </PageWithLayout>
                }
              />
              <Route
                path="/sources"
                element={
                  <PageWithLayout>
                    <Sources />
                  </PageWithLayout>
                }
              />
              <Route
                path="/blueprint"
                element={
                  <PageWithLayout>
                    <Blueprint />
                  </PageWithLayout>
                }
              />
              <Route
                path="/subscribers"
                element={
                  <PageWithLayout>
                    <Subscribers />
                  </PageWithLayout>
                }
              />
              <Route
                path="/settings"
                element={
                  <PageWithLayout>
                    <Settings />
                  </PageWithLayout>
                }
              />
              <Route
                path="/newsletterEdition/:newsletterEditionId"
                element={
                  <PageWithLayout>
                    <NewsletterEdition />
                  </PageWithLayout>
                }
              />
              <Route
                path="/newsletterEdition/new"
                element={
                  <PageWithLayout>
                    <NewsletterEdition />
                  </PageWithLayout>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <PageWithLayout>
                    <Posts />
                  </PageWithLayout>
                }
              />
            </Route>

            <Route
              path="*"
              element={
                <PageWithLayout>
                  <Login />
                </PageWithLayout>
              }
            />
          </Routes>
        </Router>
      </StatsigMetricsProvider>
    </ReduxStateProvider>
  );
};

export default App;
