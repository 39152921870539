import axios from "axios";
import { getApiUrlWithVersion } from "./environmentService";
import { getAuthUser } from "./authService";

export const createPaymentIntent = async () => {
  const response = await axios.post(
    `${getApiUrlWithVersion()}/payment_intent`,
    {},
    { headers: getAuthHeaders() },
  );

  return response.data;
};

const getAuthHeaders = () => ({ Authorization: getAuthUser()?.accessToken });
