import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavItem from "./NavItem";
import { ReactComponent as CalendarIcon } from "../assets/icons/calendar.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/settings.svg";
import { ReactComponent as ListIcon } from "../assets/icons/list.svg";
import { ReactComponent as PencilIcon } from "../assets/icons/pencil.svg";
import { ReactComponent as SubscribersIcon } from "../assets/icons/subscribers.svg";

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);

  const navigate = useNavigate();

  const handleNavigation = (page: string) => {
    setActivePage(page);
    navigate(page);
  };

  return (
    <aside
      style={{
        width: "175px",
        backgroundColor: "#ffffff",
        padding: 0,
        boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}>
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
        }}>
        <NavItem
          icon={<CalendarIcon />}
          text="Posts"
          isActive={activePage === "/dashboard"}
          onClick={() => handleNavigation("/dashboard")}
        />
        <NavItem
          icon={<ListIcon />}
          text="Sources"
          isActive={activePage === "/sources"}
          onClick={() => handleNavigation("/sources")}
        />
        <NavItem
          icon={<PencilIcon />}
          text="Blueprint"
          isActive={activePage === "/blueprint"}
          onClick={() => handleNavigation("/blueprint")}
        />
        <NavItem
          icon={<SubscribersIcon />}
          text="Subscribers"
          isActive={activePage === "/subscribers"}
          onClick={() => handleNavigation("/subscribers")}
        />
      </ul>
      <div
        className={`sidebar-settings ${activePage === "/settings" ? "active" : ""}`}
        onClick={() => handleNavigation("/settings")}>
        <NavItem
          icon={<SettingsIcon />}
          text="Settings"
          isActive={activePage === "/settings"}
          onClick={() => handleNavigation("/settings")}
        />
      </div>
    </aside>
  );
};

export default Sidebar;
