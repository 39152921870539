import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { OnboardingFormProps } from "../Types";
import { createPaymentIntent } from "../../../services/paymentService";
// stripe
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { STRIPE_API_PUBLISHABLE_KEY } from "../../../services/environmentService";

// load stripe with publishable key
const stripePromise = loadStripe(STRIPE_API_PUBLISHABLE_KEY);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const location = useLocation();
  const [returnUrl, setReturnUrl] = useState('');

  // construct return url to redirect after submitting payment
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const currentStep = Number(searchParams.get('step')) || 0;
    const newStep = currentStep + 1;
    searchParams.set('step', newStep.toString());
    const newUrl = `${window.location.origin}${location.pathname}?${searchParams.toString()}`;
    setReturnUrl(newUrl);
  }, [location]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.error(`Stripe or PaymentElement is not initialized`);
      return; // stripe hasn't loaded yet
    }

    setIsProcessing(true);

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      if (error) {
        console.error('Payment method creation error:', error);
        setErrorMessage(error.message!);
        setIsProcessing(false);
      } else {
        // stripe will redirect to the returnUrl on success
        console.log('Payment succeeded!');
        setErrorMessage('');
        setIsProcessing(false);
      }
    } catch (e: any) {
      console.error('Unexpected error:', e);
      setErrorMessage(e.message);
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={{ layout: 'tabs' }} />
      { isProcessing ?
        <p>Processing...</p> :
        <button
          type="submit"
          className="secondary-button"
          style= {{ marginTop: '16px' }}
          disabled={!stripe || !elements || isProcessing}
        >
          Submit
        </button>
      }
      { errorMessage && <div style={{ color: 'red', marginTop: "12px" }}>{errorMessage}</div>}
    </form>
  );
};

const PaymentStep: React.FC<OnboardingFormProps> = ({ formData, onChange }) => {
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    createPaymentIntent().then(data => setClientSecret(data.clientSecret));
  }, []);

  return (
    <div
      className="primary-input-container"
      style={{ minWidth: "376px" }}
    >
      { clientSecret &&
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm />
        </Elements>
      }
    </div>
  );
};

export default PaymentStep;
