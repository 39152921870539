import React, { useState, useEffect } from "react";
import Papa from 'papaparse';
import chunk from "lodash.chunk";
import { getSubscribers, uploadSubscribers } from "../services/subscribersService";
import { NewsletterSubscriber } from "../types/newsletterEdition";

const CHUNK_SIZE = 1000;

const Subscribers: React.FC = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getSubscribers().then(setSubscribers);
  }, []);

  const parseFile = (file: any) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        // validate the columns
        const columns = results.meta.fields;
        const emailKey = columns?.includes('Email') ? 'Email' : 'email';
        if (!columns?.includes('email') && !columns?.includes('Email')) {
          setErrorMessage('No email column found');
          setTimeout(() => setErrorMessage(''), 3000);
          return;
        }

        // chunk subscribers and send them to the backend
        const chunkedResults = chunk(results.data, CHUNK_SIZE);
        for (const chunk of chunkedResults) {
          const uploadPayload = chunk.map((item: NewsletterSubscriber) => ({
            email: item[emailKey as keyof NewsletterSubscriber],
            source: 'Import'
          }));
          uploadSubscribers(uploadPayload).then(() => getSubscribers().then(setSubscribers));
        }
      },
    });
  };

  const handleFileInput = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.name.endsWith('.csv')) {
        setErrorMessage('Invalid file type: please upload a csv file');
        setTimeout(() => setErrorMessage(''), 3000);
      } else {
        parseFile(file);
      }
    } else {
      console.log('No file selected');
    }
  };

  return (
    <div>
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}>
        <h1>Subscribers</h1>

        <input
          id="file-input"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileInput}
        />
        <label
          htmlFor="file-input"
          className="tertiary-info-button"
          style={{
            cursor: "pointer",
          }}
        >
          Upload
        </label>
      </div>

      { errorMessage && <div style={{ color: 'red', marginBottom: "12px" }}>{errorMessage}</div>}

      <ul
        className="newsletter-list"
      >

      { subscribers.map((subscriber: NewsletterSubscriber) => (
        <li
          key={subscriber.id}
          className="newsletter-item"
        >
          <div className="newsletter-item-content-left">
            <span className="newsletter-title">
              {subscriber.email}
            </span>
          </div>

          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}>
            <span className="list-tag-gray">
              Subscribed:{" "}
              {new Date(subscriber.created_at).toLocaleDateString()}
            </span>

            <span className="list-tag-green-active" style={{ marginRight: "12px" }}>
              {subscriber.status}
            </span>
          </div>
        </li>
      ))}

      </ul>
    </div>
  )
};

export default Subscribers;
