import { AccordionOption } from "./Types";

// Community Content
import BookImage from "../../assets/images/mom_test.jpg";
import CommunityTweetImage from  "../../assets/images/community_tweet.jpg";
import TestimonialImage from "../../assets/images/testimonial.png";
// Original Content
import OriginalContentImage from "../../assets/images/original_content.png";
// News
import ShortNewsImage from "../../assets/images/short_news_summary.png";
import NewsLinkDumpImage from "../../assets/images/news_link_dump.png";
// Jobs
import FeaturedJobImage from "../../assets/images/featured_job.png";
import JobsLinkDumpImage from "../../assets/images/jobs_link_dump.png";
// Events
import FeaturedEventImage from "../../assets/images/featured_event.png";
import EventsLinkDumpImage from "../../assets/images/events_link_dump.png";


export enum CONTENT_TYPES {
  EVENTS = "EVENTS",
  NEWS = "NEWS",
  COMMUNITY_CONTENT = "COMMUNITY_CONTENT",
  JOBS = "JOBS",
}

export const FORM_DATA_KEY_TO_DISPLAY_NAME: Record<string, string> = {
  email: "Email",
  companyWebsite: "Company Website",
  audienceOfOne: "Audience of One",
  sampleEvents: "Sample Events",
  sampleNews: "Sample News",
  sampleCommunityContent: "Community Content",
  sampleJobs: "Sample Jobs",
};

export const ONBOARDING_ACCORDION_CONTENT: Record<
  CONTENT_TYPES,
  AccordionOption
> = {
  JOBS: {
    value: `
        <ul
            style="text-align: left;"
            class="onboarding-list"
        >
            <li>Include this section if you'd like to provide job opportunities for your audience.</li>
            <li>Potions will curate relevant and timely job postings for your newsletter automatically.</li>
        </ul>
        `,
    label: "Jobs & Careers",
  },
  NEWS: {
    value: `
        <ul
            style="text-align: left;"
            class="onboarding-list"
        >
            <li>Include this section if you'd like to provide news, blogs, and research for your audience.</li>
            <li>Potions will curate, summarize, and add relevant links and content to your newsletter automatically.</li>
            <li>You can also provide links you'd like us to include in your newsletter and we will handle the rest.</li>
        </ul>
        `,
    label: "News & Research",
  },
  COMMUNITY_CONTENT: {
    value: `
        <ul
            style="text-align: left;"
            class="onboarding-list"
        >
            <li>Community content is any content sourced from your existing readers.</li>
            <li>If you select this, Potions will ask your audience for things testimonials, content they would like featured, and Q&A that you can answer.</li>
            <li>This is often best for newsletters with an existing audience.</li>
        </ul>
        `,
    label: "Community Content",
  },
  EVENTS: {
    value: `
        <ul
            style="text-align: left;"
            class="onboarding-list"
        >
            <li>Include this section if you'd like to provide events for your audience.</li>
            <li>If you select this, Potions will find and curate relevant events for your newsletter automatically.</li>
            <li>You can also provide links to your upcoming events and we will include them in your newsletter.</li>
        </ul>
        `,
    label: "Events",
  },
};

export const SECTION_FORMAT_TO_DISPLAY_NAME: Record<string, string> = {
  short_summary: "Short Summary",
  link_dump: "Link Dump",
  short_original: "Short",
  long_original: "Long",
  featured_job: "Featured",
  featured_event: "Featured",
  open_submissions: "Open Submissions",
  testimonials: "Testimonials",
  question_answers: "Question & Answers",
  polls_surveys: "Polls & Surveys",
};

export const BLUEPRINT_SECTION_EXAMPLES: Record<string, any> = {
  News: {
    short_summary: `
      <div class="sample-section-box">
        <img src=${ShortNewsImage} style="max-width: 440px; display: block; margin: auto;" />
      </div>
    `,
    link_dump: `
      <div class="sample-section-box">
        <img src=${NewsLinkDumpImage} style="max-width: 440px; display: block; margin: auto;" />
      </div>
    `,
  },
  Jobs: {
    featured_job: `
      <div class="sample-section-box">
        <img src=${FeaturedJobImage} style="max-width: 440px; display: block; margin: auto;" />
      </div>
    `,
    link_dump: `
      <div class="sample-section-box">
        <img src=${JobsLinkDumpImage} style="max-width: 440px; display: block; margin: auto;" />
      </div>
    `,
  },
  Events: {
    featured_event: `
      <div class="sample-section-box">
        <img src=${FeaturedEventImage} style="max-width: 440px; display: block; margin: auto;" />
      </div>
    `,
    link_dump: `
      <div class="sample-section-box">
        <img src=${EventsLinkDumpImage} style="max-width: 440px; display: block; margin: auto;" />
      </div>
    `,
  },
  "Community Content": {
    open_submissions: `
      <div class="sample-section-box"
        style="background-color: #0445af; color: white; border-color: #050A30;"
      >
        <h3>Is there something we should cover? 💭</h3>
        <p><a href="https://dzlivd9t4eb.typeform.com/to/yuU2S0yr"
        style="color: white; text-decoration: underline; cursor: pointer;"
        >Submit a a section to our next newsletter</a>.</p>
      </div>

      <div class="sample-section-box">
        <h3>Channing's tweet pick 🐦</h3>
        <img src=${CommunityTweetImage} alt="Community Tweet" />
        <p>This is great advice for all businesses, but it's especially great advice for indie hackers.</p>
        <p><a href="https://x.com/evankozliner">-- @evankozliner</a></p>
        <p><a href="https://dzlivd9t4eb.typeform.com/to/yuU2S0yr"
        style="color: black; text-decoration: underline;"
        >Submit a tweet to our next newsletter</a>.</p>
      </div>

      <div class="sample-section-box">
        <h3>How can I help? 🤝</h3>
        <p>Need help finding a critical hire or reviewing your pitch deck?</p>
        <p><a href="https://dzlivd9t4eb.typeform.com/to/yuU2S0yr"
        style="color: black; text-decoration: underline;"
        >Submit a request for help here.</a>.</p>
      </div>
    `,
    testimonials: `
      <div class="sample-section-box">
        <h3>From Our Community 💌</h3>
        <img src="${TestimonialImage}" alt="Testimonial" style="max-width: 300px; display: block; margin: auto;">
        <p>“Thanks to the efficiencies gained by the platform, Rho has allowed me to delay an expensive NetSuite upgrade and a senior accounting hire by at least a year.”</p>
        <p><a href="https://x.com/evankozliner">-- @evankozliner</a></p>
        <ul>
          <li>
            <p><a href="https://www.rho.co/case-studies/native-strategies?utm_source=aimply.beehiiv.com&utm_medium=newsletter&utm_campaign=a-letter-to-nyc-startup-founders-rho-weekly">Read the full story</a></p>
          </li>
          <li>
            <p><a href="https://dzlivd9t4eb.typeform.com/to/yuU2S0yr"
            style="color: black; text-decoration: underline;"
            >Submit your own testimonial here</a>.</p>
          </li>
        </ul>
      </div>
    `,
    question_answers: `
      <div class="sample-section-box">
        <h3>Q&A: What book would you recommend every founder reads?</h3>
        <img src="${BookImage}" alt="Book" style="max-width: 300px; display: block; margin: auto;">
        <p>As an engineer breaking into the world of entrepreneurship, talking to customers was one of the things I found the most counter intuitive. Everyone is going to tell you your product idea rocks, but what you need in reality is honest feedback. This book helps you get that feedback.</p>
        <p><a href="https://x.com/evankozliner">-- @evankozliner</a></p>
        <p><a href="https://dzlivd9t4eb.typeform.com/to/yuU2S0yr"
          style="color: black; text-decoration: underline;"
        >Submit your own question here</a>.</p>
      </div>
    `,
    polls_surveys: `
      <div class="sample-section-box">
        <h3>What did you think of this newsletter?</h3>
        <p>👍 50% (20 votes) | 👎 50% (20 votes)</p>
      </div>

      <div class="sample-section-box">
        <h3>What's your biggest challenge a startup founder?</h3>
        <table style="text-decoration: underline;">
          <tr>
              <td>Getting customer traction</td>
          </tr>
          <tr>
              <td>Fundraising</td>
          </tr>
          <tr>
              <td>Marketing</td>
          </tr>
        </table>
      </div>
    `,
  },
  "Original Content": {
    short_original: `
      <div class="sample-section-box">
        <img src=${OriginalContentImage} style="max-width: 440px; display: block; margin: auto;" />
      </div>
    `,
    long_original: `
      <div class="sample-section-box">
        <img src=${OriginalContentImage} style="max-width: 440px; display: block; margin: auto;" />
      </div>
    `,
  },
};

export const BLUEPRINT_SECTION_DESCRIPTIONS: Record<string, any> = {
  News: {
    short_summary: `Potions will take an online article and provide a short summary.`,
    link_dump: `A list of links to news articles and blog posts relevant to your audience.`,
  },
  Jobs: {
    featured_job: `A single link to a job descriptions, surfacing the role's title and qualifications.`,
    link_dump: `A list of links to job postings relevant to your audience.`,
  },
  Events: {
    featured_event: `A single link to an event, surfacing a description and an image.`,
    link_dump: `A list of links to events relevant to your audience.`,
  },
  "Community Content": {
    open_submissions: `Add a section in your newsletter that asks your community to submit their own freeform content.`,
    testimonials: `Potions will ask your audience or scrape your website for testimonials and surface them in your newsletter. We only show positive reviews.`,
    question_answers: `Your audience submits questions that you can (optionally) answer to include in your next newsletter.`,
    polls_surveys: `Potions will poll your audience on topics of interest and display the results in the next newsletter.`,
  },
  "Original Content": {
    short_original: `A message, overview, or takeaway to give your audience in the newsletter.`,
    long_original: `This could be a long-form blog post or content from an interview we do with you. Email evan@aimply.io to set up an interview.`,
  }
};
