import React from "react";

const DisclosureText: React.FC = () => {
  return (
    <p className="secondary-text">
      Potions <b>never</b> plagiarizes content or bypasses paywalls. At most,
      we'll give a short summary of the content you provide, and link back to
      the original source.
    </p>
  );
};

export default DisclosureText;
