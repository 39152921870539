import React, { useState } from "react";
import { OnboardingFormProps } from "../Types";

const BlueprintChoiceStep: React.FC<OnboardingFormProps> = ({ fieldKey, formData, onChange, goToNextStep }) => {

  return (
    <div>
      <h2>Build Your Blueprint</h2>
      <p>Blueprints are the template for your newsletter each week.</p>

      <div style={{ display: "flex", gap: "16px" }}>

        <div
          className="sample-section-box"
          style={{
            maxWidth: "272px",
            border: "5px solid black",
            cursor: "pointer",
          }}
          onClick={() => {
            onChange("showBlueprint", true);
            goToNextStep!();
          }}
        >
          <h3>Figure it out for me 💅</h3>
          <p>Potions will optimize the sections in your letter based on your audience and business. They'll be filled automatically each week.</p>
          <p>You can always edit it later.</p>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <button style={{ cursor: "pointer" }} className="tertiary-info-button">Select</button>
          </div>
        </div>

        <div
          className="sample-section-box"
          style={{
            maxWidth: "272px",
            border: "5px solid black",
            cursor: "pointer",
          }}
          // this should be false to show the existing newsletter
          onClick={() => {
            onChange("showBlueprint", false);
            goToNextStep!();
          }}
        >
          <h3>Use an existing newsletter ♻️</h3>
          <p>You provide Potions with a link to a newsletter, and Potions will intelligently use it's template to build your newsletter.</p>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "54px" }}>
            <button style={{ cursor: "pointer" }} className="tertiary-info-button">Select</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlueprintChoiceStep;
